@import '~antd/dist/antd.css';

html {
  font-size: 16px;
}

@media (max-width: 900px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 12px;
  }
}

.header {
  background-color: #009bbb;
  align-items: 'center';
  justify-content: 'center';
}

.header-text {
  color: '#FFFFFF';
}

.color-primary {
  background-color: #009bbb;
}

.color-success {
  color: #27ae60;
}

.color-warning {
  color: #e3ab05;
}

.color-danger {
  color: #d9453c;
}

.color-medium-gray {
  background-color: #c7d5cb;
}

.bg-add {
  background: rgba(39, 174, 96, 0.2);
}

.bg-delete {
  background: rgba(217, 69, 60, 0.2);
}

.bg-edit {
  background: rgba(255, 210, 7, 0.2);
}

.full-height {
  height: 100vh;
}

.h100 {
  height: 100%;
}

.p-1 {
  padding: 0.5em;
}

.pr-1 {
  padding-right: 0.5em;
}

.pl-1 {
  padding-left: 0.5em;
}

.py-1 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.py-2 {
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.mt-1 {
  margin-top: 0.5em;
}

.mt-2 {
  margin-top: 1em;
}

.mb-1 {
  margin-bottom: 0.5em;
}

.mb-2 {
  margin-bottom: 1em;
}

.burger-preview {
  background-color: #eef0ef;
}

.burger-preview:hover {
  background-color: #cdd3ce;
}

.download-csv,
.download-csv:focus {
  background-color: #1f6e43 !important;
  border: none;
}

.download-csv:hover {
  background-color: #1f6e43 !important;
  filter: brightness(1.3);
}

.my-select .ant-select-selector {
  border-radius: 8px !important;
  background: transparent !important;
  color: #45474E !important;
}

.my-select .ant-select-selection-placeholder {
  color: #191C20;
  font-weight: 500;
}

.select-full .ant-select-selector,
.select-full .ant-select,
.select-full .ant-input,
.select-full .ant-space-item,
.select-full .ant-picker
{
  width: 100% !important;
}

.select-full .ant-select-selector,
.select-full .ant-input,
.select-full .ant-input-number,
.select-full .ant-input-number-input,
.select-full .ant-input-number-input-wrap
{
  height: 40px !important;
  border-radius: 4px !important;
  background: transparent !important;
  max-width: 100% !important;
}

.select-full .ant-picker-input
{
  height: 30px !important;
  border-radius: 4px !important;
  background: transparent !important;
  max-width: 100% !important;
}

.select-full .ant-picker {
  background: transparent !important;
}

.select-full .ant-select-selection-placeholder,
.select-full .ant-select-selection-item,
.select-full .ant-input-number-input,
.select-full .ant-picker-input
{
  align-items: center;
  display: flex;
  color: #191C20;
  font-weight: 500;
  max-width: 100% !important;
}

.select-full input::placeholder {
  color: #191C20;
  font-weight: 500 !important;
}

.my-button {
  background: #415F91;
  color: white;
}

.my-button:hover {
  background: #415F91;
  color: white;
  filter: brightness(120%);
}

.my-button:disabled
{
  background: #1D1B201F !important;
  color: #191C20 !important;
  filter: opacity(0.5);
}
